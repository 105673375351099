<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
                <AMonthPicker
                    class="mr-2 mt-2"
                    v-model:value="state.params.month"
                    placeholder="Pilih Bulan"
                    style="width:300px"
                    format="MMMM YYYY"/>
                <FilterDistributor
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.distributor"/>
                <FilterGudang
                    class="mr-2"
                    v-can:hide.distributor
                    show-code
                    v-model:value="state.params.gudang"
                    v-model:distributor="state.params.distributor"/>
                <AButton
                    class="mr-2"
                    type="primary"
                    title="cari"
                    @click="btnFetchData()"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </div>
        </div>

        <!-- bulanan gudang -->
        <div class="row mt-5">
            <!-- Transaksi Stok Gudang Bulanan (MDXL) -->
            <div class="content-card col-lg-6 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-end">
                            <div class="col-lg-6 col-md-12">
                                <strong>Transaksi Stok Gudang Bulanan (MDXL)</strong>
                            </div>
                            <div class="col-lg-6 col-md-12 text-right mt-auto">
                                <DownloadExcel
                                    :url="state.url.mdxl_monthly"
                                    :params="state.params"
                                    namefile="Laporan-Transaksi-Stok-Gudang-Bulanan-MDXL"
                                    @errors="errorMessage"/>
                            </div>
                        </div>

                        <!-- list table -->
                        <div class="table-responsive text-nowrap mt-4">
                            <TableStandard
                                v-if="!state.isFetching"
                                :url="state.url.mdxl_monthly"
                                :params="state.params"
                                :columns="state.columns"
                                @errors="errorMessage"/>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Transaksi Stok Gudang Bulanan (POS) -->
            <div class="content-card col-lg-6 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-end">
                            <div class="col-lg-6 col-md-12">
                                <strong>Transaksi Stok Gudang Bulanan (POS)</strong>
                            </div>
                            <div class="col-lg-6 col-md-12 text-right mt-auto">
                                <DownloadExcel
                                    :url="state.url.pos_monthly"
                                    :params="state.params"
                                    namefile="Laporan-Transaksi-Stok-Gudang-Bulanan-POS"
                                    @errors="errorMessage"/>
                            </div>
                        </div>

                        <!-- list table -->
                        <div class="table-responsive text-nowrap mt-4">
                            <TableStandard
                                v-if="!state.isFetching"
                                :url="state.url.pos_monthly"
                                :params="state.params"
                                :columns="state.columns"
                                @errors="errorMessage"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- harian gudang -->
        <div class="row mt-5">
            <!-- Transaksi Stok Gudang Harian (MDXL) -->
            <div class="content-card col-lg-6 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-end">
                            <div class="col-lg-6 col-md-12">
                                <strong>Transaksi Stok Gudang Harian (MDXL)</strong>
                            </div>
                            <div class="col-lg-6 col-md-12 text-right mt-auto">
                                <DownloadExcel
                                    :url="state.url.mdxl_daily"
                                    :params="state.params"
                                    namefile="Laporan-Transaksi-Stok-Gudang-Harian-MDXL"
                                    @errors="errorMessage"/>
                            </div>
                        </div>

                        <!-- list table -->
                        <div class="table-responsive text-nowrap mt-4">
                            <TableStandard
                                v-if="!state.isFetching"
                                :url="state.url.mdxl_daily"
                                :params="state.params"
                                :columns="state.columns"
                                @errors="errorMessage"/>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Transaksi Stok Gudang Harian (POS) -->
            <div class="content-card col-lg-6 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-end">
                            <div class="col-lg-6 col-md-12">
                                <strong>Transaksi Stok Gudang Harian (POS)</strong>
                            </div>
                            <div class="col-lg-6 col-md-12 text-right mt-auto">
                                <DownloadExcel
                                    :url="state.url.post_daily"
                                    :params="state.params"
                                    namefile="Laporan-Transaksi-Stok-Gudang-Harian-POST"
                                    @errors="errorMessage"/>
                            </div>
                        </div>

                        <!-- list table -->
                        <div class="table-responsive text-nowrap mt-4">
                            <TableStandard
                                v-if="!state.isFetching"
                                :url="state.url.post_daily"
                                :params="state.params"
                                :columns="state.columns"
                                @errors="errorMessage"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, nextTick } from 'vue'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterGudang from '@/components/filter/FilterGudang'
import TableStandard from '@/components/Molecules/TableStandard'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import { pickBy } from 'lodash'
import moment from 'moment'

export default defineComponent({
    components: {
        FilterDistributor,
        FilterGudang,
        TableStandard,
        DownloadExcel,
    },
    setup() {
        const errorMessage = ref()

        const state = reactive({
            columns: [
                {
                    title: 'Tanggal',
                    dataIndex: 'tanggal',
                },
                {
                    title: 'Kode Gudang',
                    dataIndex: 'kode_gudang',
                },
                {
                    title: 'Nama Gudang',
                    dataIndex: 'nama_gudang',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'kode_distributor',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'nama_distributor',
                },
                {
                    title: 'QTY ZAK',
                    dataIndex: 'volume_zak',
                    align: 'right',
                    customRender: ({ text }) => parseInt(text).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                },
            ],
            url: {
                mdxl_monthly: '/api/report/metabase-v2/transaksi-stok-gudang-bulanan-distributor',
                mdxl_daily: '/api/report/metabase-v2/transaksi-stok-gudang-harian-distributor',
                pos_monthly: '/api/report/metabase-v2/transaksi-stok-gudang-bulanan-distributor-pos',
                post_daily: '/api/report/metabase-v2/transaksi-stok-gudang-harian-distributor-pos',
            },
            isFetching: false,
            params: {
                q: '',
                distributor: [],
                gudang: [],
                month: moment(new Date()).startOf('month').format('YYYY-MM'),
                page: 1,
                "per-page": 10,
            },
        })

        const btnFetchData = async () => {
            let params = state.params

            if (state.params.month) {
                params.month = moment(state.params.month).format('YYYY-MM')
            }

            state.params = pickBy(params)

            state.isFetching = true
            await nextTick()
            state.isFetching = false
        }

        onMounted(() => {
            btnFetchData()
        })

        return {
            state,
            btnFetchData,
            errorMessage,
        }
    },
})
</script>

<style lang="scss" scoped>
.card {
    height: 100%;
    .card-body {
        margin-top: 0;
    }
}
</style>
